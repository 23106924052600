import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { renderEntries } from 'client/site-modules/industry-center/utils/entries-renderer';
import { IndustryPageSubNavigation } from 'client/site-modules/industry-center/components/sub-navigation/industry-page-sub-navigation';
import { TrackingMap } from 'client/tracking/maps/industry-center/index';
import { TrackingHandler } from 'client/tracking/handler';
import { IndustryCenterSidebar } from 'site-modules/shared/components/industry-center/industry-center-sidebar/industry-center-sidebar';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';
import { getIndustryArticleBreadcrumbs, getItems } from 'client/site-modules/industry-center/utils/utils';

const SUB_NAVIGATOR_ID = 'sub-navigation';
const SIDEBAR_ID = 'right-rail';

export class IndustryArticle extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    content: CmsEntities.Content,
  };

  static defaultProps = {
    location: {},
    content: DEFAULT_CONTENT,
  };

  componentDidMount() {
    TrackingHandler.useMap(TrackingMap);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.content !== nextProps.content;
  }

  render() {
    const { content, location } = this.props;
    const { pathname } = location;
    const { title } = content;
    const contentEntries = content.children();
    const subNav = content.child(SUB_NAVIGATOR_ID);
    const sidebar = content.child(SIDEBAR_ID);
    const breadcrumbs = getIndustryArticleBreadcrumbs(pathname, title);

    return (
      <Fragment>
        <IndustryPageSubNavigation subNav={subNav} />
        <Container>
          <Breadcrumbs data={breadcrumbs} className="mb-0" />
          {!!sidebar && sidebar.children().length ? (
            <Row>
              <Col xs={12} md={8} lg={9}>
                {renderEntries({ entriesToRender: contentEntries, pathname })}
              </Col>
              <Col xs={12} md={4} lg={3}>
                <IndustryCenterSidebar sidebar={getItems(sidebar, SIDEBAR_ID).pop()} />
              </Col>
            </Row>
          ) : (
            renderEntries({ entriesToRender: contentEntries, pathname })
          )}
        </Container>
      </Fragment>
    );
  }
}
