import React from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { SidebarContactGroup } from './industry-center-sidebar-contact-group';

export function IndustryCenterSidebar({ sidebar }) {
  const entriesTypes = {
    mediaContacts: /media-contacts/,
    miscItems: /misc-items/,
  };

  if (!sidebar || !sidebar.children().length) return <div />;

  return sidebar
    .children()
    .reduce((contactEntries, contactGroup) => {
      switch (true) {
        case entriesTypes.mediaContacts.test(contactGroup.id): {
          contactEntries.push(
            <SidebarContactGroup data={contactGroup} title={'Media Contacts'} key={contactGroup.id} />
          );
          break;
        }
        case entriesTypes.miscItems.test(contactGroup.id): {
          /* TODO remove after UI/UX created for this contact group */
          contactEntries.push(<div className="misc-items-placeholder" key={contactGroup.id} />);
          break;
        }
        default:
          break;
      }
      return contactEntries;
    }, [])
    .map(item => item);
}

IndustryCenterSidebar.propTypes = {
  sidebar: CmsEntities.Content,
};

IndustryCenterSidebar.defaultProps = {
  sidebar: null,
};
