import React from 'react';
import { get } from 'lodash';
import { pageDefinition } from 'client/site-modules/shared/pages/page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { CmsModel, bindToContent } from 'client/data/models/cms';
import { SitemapModel, buildPagePath } from 'client/data/models/sitemap';
import { parseContent } from 'client/data/cms/content';
import { updateExtTagAttribute } from 'client/actions/external-tag-attribute';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { handle404Error } from 'client/site-modules/shared/utils/errors';
import { IndustryCenterDecorator } from 'site-modules/shared/pages/decorators/industry-center-decorator';
import { getSeoObject } from 'client/site-modules/industry-center/utils/seo';
import { IndustryArticle } from './industry-article';

export const DEFAULT_ARTICLE_ID = 'index';

/**
 * Returns content path id by url pathname.
 *
 * @param {String} articleCategory - article category
 * @param {String} articleId - article id
 * @return {String} path - content path.
 */
export function getContentPath(articleCategory, articleId = DEFAULT_ARTICLE_ID) {
  return `/industry/${articleCategory}/${articleId},/${articleCategory}/${articleId}&fetchcontents=true`;
}

export const connectToModelConfiguration = {
  content: bindToContent(props => {
    const { articleCategory, articleId } = props.match.params;
    return getContentPath(articleCategory, articleId);
  }),
};

export const IndustryArticleUI = connectToModel(IndustryArticle, connectToModelConfiguration);

export const pageDefinitionConfig = (articleCategory, articleId) => {
  const articlePath = getContentPath(articleCategory, articleId);
  return {
    name: 'industry-article',
    category: 'industry',
    preload: (store, props) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(`content["${articlePath}"]`, CmsModel);

      return preloader
        .load()
        .then(modelState => {
          const content = modelState.get(`content["${articlePath}"]`, CmsModel);

          if (!content && !content.id) {
            throw handle404Error({ errorMessage: 'IndustryArticleDefinition - Content is empty' });
          }

          const parsedContent = parseContent(content);

          if (parsedContent.hasChild('dynamic-article-list')) {
            const pathname = get(props, ['location', 'pathname'], '');
            preloader.resolve(buildPagePath(`${pathname}sitemap.html`), SitemapModel);
          }

          return preloader.load().then(() => {
            store.dispatch(updateExtTagAttribute(content.contentMetadata.extTagAttribute));
          });
        })
        .catch(error => {
          const ex = error;
          /*
           * Only redirect to 404 if article api returned 404
           * Original message:
           * `Request to ${url} failed, got status 404`
           */
          if (error.message.includes(articlePath) && error.message.includes('got status 404')) {
            ex.redirectPageTo404 = true;
          }
        });
    },
    seo: (state, props) => ({
      ...getSeoObject(state, articlePath, get(props, ['location', 'pathname'], '')),
      robots: articlePath.includes('/industry/support/carcode')
        ? {
            noIndex: true,
            noFollow: true,
          }
        : undefined,
    }),
    component: props => <IndustryArticleUI {...props} articleCategory={articleCategory} />,
    decorator: IndustryCenterDecorator,
  };
};

/**
 * Returns constructor for dynamic page definition.
 *
 * @param  {Object} options
 * @param  {String} options.articleCategory article category from router.
 * @param  {String} options.articleId article id from router.
 * @return {Object} page definition for this article.
 *
 * Simple memoization added to prevent page rerender
 * Generated pageDefinitions added to array
 * Example: pages['press']
 */
const pages = new Map();
export default ({ articleCategory, articleId = DEFAULT_ARTICLE_ID }) => {
  const pageName = `${articleCategory}${articleId}`;
  let page = pages.get(pageName);
  if (!page) {
    page = pageDefinition(pageDefinitionConfig(articleCategory, articleId));
    pages.set(pageName, page);
  }
  return page;
};
