import React from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { isEmpty } from 'lodash';

export function SidebarContact({ contact }) {
  const entryContent = contact.getAllMetadata();
  if (isEmpty(entryContent)) return null;

  const {
    'contact-image': contactImage,
    'contact-name': contactName,
    'contact-title': contactTitle,
    'contact-email': contactEmail,
    'contact-phone': contactPhone,
  } = entryContent;

  const defaultContactTitle = 'Send us an email or call us at:';
  const defaultContactName = 'General Inquiries';

  return (
    <Row className="mb-1 text-gray-darker">
      <Col xs={3}>
        {contactImage ? (
          <img alt="contact" className="img-fluid rounded-circle" src={contactImage} />
        ) : (
          <div className="placeholder" />
        )}
      </Col>
      <Col>
        <Row>
          <Col className="mb-0_5">
            <div>{contactName || defaultContactName}</div>
          </Col>
        </Row>
        <Row>
          <Col className="size-12">
            <div>{contactTitle || defaultContactTitle}</div>
          </Col>
        </Row>
        <Row>
          <Col className="size-12">
            <a className="text-primary-darker" href={`mailto:${contactEmail}`}>
              {contactEmail}
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="size-12">
            <div className="text-primary-darker">{contactPhone}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

SidebarContact.propTypes = {
  contact: PropTypes.shape({}),
};
SidebarContact.defaultProps = {
  contact: {},
};

export function SidebarContactGroup({ data, title }) {
  return data.children().length ? (
    <Container className={'standard-spacing mt-1 mx-1 mx-md-0'}>
      <Row>
        <Col className="heading-3 mb-2">{title}</Col>
      </Row>
      {data
        .children()
        .reduce((contactGroup, contactData) => {
          contactGroup.push(<SidebarContact key={contactData.id} contact={contactData} />);
          return contactGroup;
        }, [])
        .map(item => item)}
    </Container>
  ) : (
    <div />
  );
}

SidebarContactGroup.propTypes = {
  data: PropTypes.shape({}),
  title: PropTypes.string,
};
SidebarContactGroup.defaultProps = {
  data: {},
  title: '',
};
